import React, { ReactElement } from "react"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Seo from "../components/SEO"

import { styled } from "twin.macro"
import Badge from "../components/Badge"

const Main = styled.main`
  font-family: "Lato", "Noto Sans JP", "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
`

function Index(): ReactElement {
  return (
    <React.StrictMode>
      <Seo title="認証マーク設置方法" />
      <Navbar />
      <Main>
        <Badge />
      </Main>
      <Footer />
    </React.StrictMode>
  )
}

export default Index
