import { ChangeEvent } from "@reach/router/node_modules/@types/react"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { FaCopy } from "react-icons/fa"

import * as Styled from "./styles"

export default function Badge() {
  const [color, setColor] = useState("normal")
  const [bg, setBg] = useState("#FFFFFF")

  const setColorHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    setColor(e.target.value)
  }

  const setBGHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setBg(e.target.value)
  }

  const CopyToClipboard = (toCopy) => {
    const el = document.createElement(`textarea`)
    el.value = toCopy
    el.setAttribute(`readonly`, ``)
    el.style.position = `absolute`
    el.style.left = `-9999px`
    document.body.appendChild(el)
    el.select()
    document.execCommand(`copy`)
    document.body.removeChild(el)
    alert(`「${toCopy}」をコピーしました。`)
  }

  return (
    <Styled.Section>
      <Helmet>
        <script
          defer
          src="https://cert.carbonneutral-web.org/cnw.min.js"
        ></script>
      </Helmet>
      <Styled.Container>
        <Styled.SectionHeader>
          <Styled.SectionSubTitle>Certification Badge</Styled.SectionSubTitle>
          <Styled.SectionTitle>認証マーク設置方法</Styled.SectionTitle>
        </Styled.SectionHeader>
        <Styled.SectionText>
          Carbon Neutral
          Webの認証マークをホームページに設置するには、下記にて設定をしていただいて、出力されるソースをご利用して設置をお願いします。
        </Styled.SectionText>
        <Styled.SourceBox>
          <Styled.SettingsBar>
            <Styled.SettingsColor>
              認証マークの色
              <select onChange={setColorHandler}>
                <option value="normal">通常</option>
                <option value="white">白抜き</option>
                <option value="black">黒抜き</option>
              </select>
            </Styled.SettingsColor>
            <Styled.SettingsBG>
              <div>
                背景色
                <small>（※確認用　認証マークには反映されません。）</small>
              </div>
              <Styled.BGColor bgColor={bg}>
                <input
                  onChange={setBGHandler}
                  type="color"
                  id="bg-select"
                  value={bg}
                />
              </Styled.BGColor>
            </Styled.SettingsBG>
          </Styled.SettingsBar>
          <Styled.Wrap>
            <Styled.SourceCode>
              <code>
                {`<div id="cnw" class="carbonbadge${
                  color === "normal" ? "" : " " + color
                }"></div>`}
                <br />
                {`<script src="https://cert.carbonneutral-web.org/cnw.min.js" defer></script>`}
              </code>
              <Styled.CopyButton
                onClick={() => {
                  CopyToClipboard(
                    `<div id="cnw" class="carbonbadge${
                      color === "normal" ? "" : " " + color
                    }"></div><script src="https://cert.carbonneutral-web.org/cnw.min.js" defer></script>`
                  )
                }}
              >
                <FaCopy />
                コピー
              </Styled.CopyButton>
            </Styled.SourceCode>
            <Styled.Preview bgColor={bg}>
              <div
                id="cnw"
                className={`carbonbadge${
                  color === "normal" ? "" : " " + color
                }`}
              ></div>
            </Styled.Preview>
          </Styled.Wrap>
        </Styled.SourceBox>
        <Styled.SectionText>
          {" "}
          認証マークに説明文を添える場合はこちらのテキストを使用してください。
        </Styled.SectionText>
        <Styled.SourceCode className="full">
          このウェブサイトの利用によって発生しているCO2排出量は、オフセットされ、実質“０”となっております。
          <Styled.CopyButton
            onClick={() => {
              CopyToClipboard(
                `このウェブサイトの利用によって発生しているCO2排出量は、オフセットされ、実質“０”となっております。`
              )
            }}
          >
            <FaCopy />
            コピー
          </Styled.CopyButton>
        </Styled.SourceCode>

        <Styled.Box>
          <Styled.SectionSubTitle>使用時の注意事項</Styled.SectionSubTitle>
          <Styled.List>
            <li>
              ご登録頂いているドメインに存在されているページにのみ使用できます。
            </li>
            <li>
              ホームページへ設置後、表示されない場合は、設置はそのままに、営業担当にお知らせください。
            </li>
          </Styled.List>
        </Styled.Box>
      </Styled.Container>
    </Styled.Section>
  )
}
