import tw, { styled } from "twin.macro"
import * as Common from "../../common/styles"

export const Container = Common.Container

export const Section = styled(Common.Section)`
  ${tw`bg-gray-100 pb-24`}
`

export const SectionSubTitle = Common.SectionSubTitle
export const SectionTitle = styled(Common.SectionTitle)`
  ${tw`md:mb-16`}
`
export const SectionHeader = Common.SectionHeader
export const SectionText = styled(Common.SectionText)`
  ${tw`text-left max-w-full text-center md:text-base`}
`

export const Heading = styled.h3`
  ${tw`text-2xl text-primary text-left pt-4 mb-4`}
`

export const Box = styled.div`
  ${tw`rounded-xl shadow px-6 md:px-10 py-6 max-w-5xl md:py-8 mx-auto my-8 bg-white leading-relaxed mt-24`}
  p:not(last-of-type) {
    ${tw`mb-2`}
  }
`

export const SettingsBar = styled.div`
  ${tw`px-8 py-8 md:py-4 bg-primary text-white flex flex-col md:flex-row items-center justify-center gap-4 md:gap-12`}
`

export const SettingsBG = styled.div`
  ${tw`flex items-center gap-2`}

  input[type="color"] {
    ${tw`w-24 opacity-0`}
    -webkit-appearance: none;
    border: none;
  }
`

export const SettingsColor = styled.div`
  ${tw`flex items-center gap-4`}

  select {
    ${tw`w-24 text-black bg-white px-2 py-1 rounded-md text-sm`}
  }
`

export const BGColor = styled.div`
  ${tw`border-gray-800 border-2 rounded-md`}

  ${({ bgColor }) => `background-color: ${bgColor};`}
`

export const Preview = styled.div`
  ${tw`p-8 md:w-1/3 m-4 rounded-md shadow-lg flex justify-center items-center`}
  ${({ bgColor }) => `background-color: ${bgColor};`}
`

export const SourceBox = styled(Box)`
  ${tw`bg-gray-200 overflow-hidden text-left mb-12`}
  padding: 0 !important;

  p:not(last-of-type) {
    ${tw`mb-2`}
  }
`

export const Wrap = styled.div`
  ${tw`md:flex justify-between`}
`
export const SourceCode = styled.div`
  ${tw`m-4 px-8 py-6 text-gray-800 md:w-2/3 text-sm shadow-lg rounded-md bg-gray-300 flex flex-col gap-4`}

  &.full {
    ${tw`text-left mx-auto`}
  }
`

export const List = styled.ul`
  ${tw`list-inside list-disc text-left leading-relaxed text-gray-500 mt-4`}
  li {
    ${tw`mb-1 `}
  }
`

export const TextTitle = styled.h3`
  ${tw`text-2xl text-primary text-left pt-4 mb-4`}
`
export const CopyButton = tw.button`mt-auto flex self-end gap-2 items-center justify-center text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md hover:bg-gray-200`
